import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Link as ScrollLink } from "react-scroll"
import Gallery from "./Gallery/Gallery"
import { StaticImage } from "gatsby-plugin-image"
import paths from "../../constants/paths"

const TreeImgUrl = "../../images/illustrations/Tree-linework-medium.png"

const GridContainer = styled.div`
  position: relative;
`

const TitleArea = styled.div`
  height: 155px;
  position: relative;
  background-color: var(--brand-color-off-white);
  width: 100%;
  overflow: hidden;

  @media (min-width: 768px) {
    width: auto;
    height: auto;
  }
`

const Buttons = styled.div`
  text-transform: uppercase;
  text-align: center;
  bottom: 0;
  left: 0;
  right: 0;

  a {
    position: relative;
    margin: 1rem 0.5rem 0;
  }

  @media (min-width: 768px) {
    position: absolute;
    top: -60px;
    right: 0;
    text-align: right;
    margin-right: 1rem;
    height: auto;
    
    a {
      display: inline-block;
      margin: 0;
      width: auto;
      margin-bottom: 0.5rem;
      
      &:first-child {
        margin-right: .5rem;
      }
    }
  }
`

const TitleWithTrees = styled.div`
  position: relative;
  margin: 0 auto;
  width: 200px;
  
  > div {
    position: relative;
  }

  h1 {
    font-weight: normal;
    font-size: 1rem;
    padding: 30px 0 0;
    margin: 0;
  }

  .trees {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    > div {
      position: absolute;
      bottom: -114px;
      height: 285px;
      -webkit-transform: scale(0.5);
      -ms-transform: scale(0.5);
      transform: scale(0.4);

      @media (min-width: 768px) {
        transform: scale(0.5);
      }

      &:nth-child(1) {
        left: -140px;
    
        @media (min-width: 768px) {
          bottom: -75px;
        }
      }

      &:nth-child(2) {
        right: -100px;
        z-index: 25;
        bottom: -125px;
        img {
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }

        @media (min-width: 768px) {
          bottom: -88px;
        }
      }

      &:nth-child(3) {
        right: -163px;
        z-index: 20;
      
        @media (min-width: 768px) {
          bottom: -75px;
          right: -175px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    width: 500px;
    h1 { 
      font-size: var(--h1);
      padding: 2rem 0;
    }
  }

  @media (min-width: 1200px) {
    width: 750px;
  }
`;

const Banner = ({ videoAnchor }) => {
  const videoElementId = videoAnchor().props.id
  
  return (
    <GridContainer>
      <Gallery/>
      <TitleArea>
        <Buttons>
          <Link className="pure-button-primary pure-button" to={paths.contact.url}>
            Contact Me!
          </Link>
          <ScrollLink to={videoElementId} spy={true} smooth={true} offset={-70} duration={500} className="button-secondary pure-button">
            Watch Video
          </ScrollLink>
        </Buttons>
        <TitleWithTrees>
          <div>
            <h1>Your Pacific Northwest Adventure Wedding Photographer</h1>
            <div className="trees">
              <StaticImage alt="Northwest Tree illustration" src={TreeImgUrl} height={350}/>
              <StaticImage alt="Northwest Tree illustration" src={TreeImgUrl} height={270}/>
              <StaticImage alt="Northwest Tree illustration" src={TreeImgUrl} height={350}/>
            </div>
          </div>
        </TitleWithTrees>
      </TitleArea>
    </GridContainer>
  )
}

export default Banner
