import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import TouchCarousel from 'react-touch-carousel'
import Card from './Card'
import Preloader from './Preloader'

const autoplaySpeedInMillis = 5000 // in millis
// const autoplaySpeedInMillis = false // false while deving the styles

const CarouselContainerDiv = styled.div`
  background: var(--brand-color-white);
  position: relative;
  height: calc(100vh - 76px - 155px);
  min-height: 442px;
  width: 100%;
  min-width: 290px;
  margin: 0;
  overflow: hidden;
  touch-action: pan-y;

  @media (min-width: 768px) {
    height: calc(100vh - 76px - 168px);
  }
  @media (min-width: 1200px) {
    height: calc(100vh - 76px - 208px);
  }
`

const CarouselTrack = styled.div`
  display: flex;
  height: 100%;
`

const imagesQuery = graphql`
  fragment fluidHomeGalleryImage on File {
    name
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 90,
          placeholder: NONE
        )
      }
    }

  query HomeGalleryImages {
    allFile(filter:{
      relativePath:{glob:"portfolios/home/*"}
    },
    sort:{
      fields:[name],
      order:ASC
    }) {
      edges {
        node {
          ...fluidHomeGalleryImage
        }
      }
    }
  }
`

const CarouselContainer = (props) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateDimensions = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions();
  }, [])

  const { cursor, ...rest } = props
  const translateX = cursor * width
  return (
    <CarouselContainerDiv>
      <CarouselTrack
        style={{ transform: `translateX(${translateX}px)` }}
        {...rest}
      />
    </CarouselContainerDiv>
  )
}

const Gallery = () => {
  const [width, setWidth] = useState(false);
  // For some reason, the autoplaySpeedInMillis is not being updated, so I'm starting with
  // this as the initial state for now.
  const [carouselState, setCarouselState] = useState({ autoplayLoopProp: autoplaySpeedInMillis, imageToPreload: null })
  
  const autoplayLoopProp = carouselState.autoplayLoopProp
  const imageToPreload = carouselState.imageToPreload

  useEffect(() => {
    const updateDimensions = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions();
  }, [])

  const imagesQueryResult = useStaticQuery(imagesQuery)

  const items = imagesQueryResult.allFile.edges.map((edge, index) => {
    return {
      name: edge.node.name,
      childImageSharp: edge.node.childImageSharp
    }
  })

  /**
   * Used to seed the preloader for the next card
   */
  const imageRenderedCallback = (currentIndex) => {
    const newState = { ...carouselState }
    let stateChanged = false;

    if (items.length >= currentIndex + 2) {
      const nextItem = items[currentIndex + 1];
      newState.imageToPreload = nextItem.childImageSharp.gatsbyImageData;
      stateChanged = true;
    }

    // This doesn't always trigger for some reason.
    // if (!autoplayLoopProp) {
    //   newState.autoplayLoopProp = autoplaySpeedInMillis;
    //   stateChanged = true;
    // }

    if (stateChanged) {
      setCarouselState(newState);
    }
  }

  const renderCard = (index, modIndex) => {
    return (
      <Card
        item={items[modIndex]}
        modIndex={modIndex}
        index={index}
        imageRenderedCallback={imageRenderedCallback} />
    )
  }

  const getCarousel = () => {
    const props = {
      component: CarouselContainer,
      renderCard: renderCard,
      cardCount: items.length,
      cardPadCount: items.length,
      tension: 100,
      friction: 25,
      loop: true,
      autoplay: autoplayLoopProp
    }

    // Loads a temporary carousel with one image while the component is being mounted.
    if (width) {
      return <TouchCarousel {...props} />
    }

    return <TouchCarousel {...props} cardSize={width} />
  }

  return (
    <React.StrictMode>
      {getCarousel()}
      <Preloader gatsbyImageData={imageToPreload} />
    </React.StrictMode>
  )
}

export default Gallery
