import React from "react"
import loadable from '@loadable/component'
import Banner from "../components/Home/Banner"
import Seo from "../components/SEO"
import { Link } from "gatsby"
import styled from "styled-components"
import paths from "../constants/paths"
import { StaticImage } from "gatsby-plugin-image"
import AboutVideoWrapper from "../components/Home/AboutVideoWrapper"

const HomeIntro = loadable(() => import("../components/Home/HomeIntro"))
const FeaturedItems = loadable(() => import("../components/Home/FeaturedItems"))
const HighlightedReviews = loadable(() => import("../components/Home/HighlightedReviews"))

const ContactMe = styled.div`
  background: var(--brand-color-off-white);
  margin: auto 0;
  padding: 1rem;
  text-align: center;

  @media (min-width: 768px) {
    padding-top: 2rem;
  }
`

const SeaToMountainBlock = styled.div`
  background: var(--brand-color-off-white);
  position: relative;
  text-align: center;
  width: 100%;
  overflow: hidden;

  @media (min-width: 768px) {
    width: auto;
    overflow: visible;
  }
`

const LupineHeader = styled.div`
  display: inline-block;
  position: relative;

  h2 {
    margin-top: 0;
    padding: 2rem;
    width: 290px;
    margin: 0;
  }

  @media (min-width: 768px) {
    h2 {
      padding: 0 2rem 2rem;
      width: 400px;
    }
  }

  @media (min-width: 1200px) {
    h2 {
      width: 550px;
    }
  }
`

const LupineImgDiv = styled.div`
  position: absolute;
  right: -45px;
  top: -60px;
  transform: scale(0.6);

  @media (min-width: 768px) {
    right: -60px;
    top: -80px;
    transform: scale(0.8);
  }

  @media (min-width: 768px) {
    transform: scale(1);
    right: -80px;
    top: -100px;
  }
`

const Bird = styled.div`
  text-align: center;
  margin: -30px 40px -20px 0;

  img {
    height: 90px;
  }
`

const AdventureWeddings = styled.div`
  background: var(--brand-color-white);
  position: relative;

  @media (min-width: 768px) {
    p {
      margin: 1rem auto;
      max-width: 700px;
    }
  }

  @media (min-width: 1200px) {
    margin: 80px 0;
  }
`

const AdventureWeddingsLinkP = styled.p`
  @media (min-width: 768px) {
    text-align: center;
  }
`

const Collections = styled.div`
  position: relative;

  article {
    text-align: center;
  }

  article > div {
    display: inline-block;
    margin: 0 auto;
    position: relative;
  }

  h2 {
    margin: 0;
    padding: 2rem 0;
    margin-left: 5rem;
    width: 210px;
  }
  .gatsby-image-wrapper {
    position: absolute;
    top: 5px;
    left: -30px;
    transform: scale(0.6);
    height: 110px;
  }
  p {
    margin-top: 0;
    padding-top: 0;
    text-align: center;
  }

  @media (min-width: 768px) {
    h2 {
      padding: 3rem 0;
      width: 430px;
    }
    .gatsby-image-wrapper {
      overflow: visible;
      top: 45px;
      left: -10px;
      transform: scale(0.8);
    }
  }

  @media (min-width: 1200px) {
    h2 {
      width: 540px;
    }
    .gatsby-image-wrapper {
      transform: scale(1);
      left: -50px;
      top: 60px;
    }
  }
`

const MeadowImg = styled.div`
  position: relative;

  .gatsby-image-wrapper {
    max-height: 100vh;
  }
  .stamp {
    position: absolute;
    top: -20px;
    right: -20px;
    transform: scale(0.6) rotate(-20deg);
  }

  @media (min-width: 1024px) {
    .stamp {
      top: 40px;
      right: 40px;
      transform: scale(0.8) rotate(-20deg);
    }
  }

  @media (min-width: 1200px) {
    .stamp {
      top: 100px;
      right: 100px;
      transform: scale(1) rotate(-20deg);
    }
  }
`

const JustBelowFold = styled.div`
  background: var(--brand-color-off-white);

  p, h2 {
    max-width: 900px;
    margin: 1rem auto;
  }
`

const Index = ({ location }) => {
  return (
    <>
      <Seo title={paths.home.title} location={location} />
      <Banner videoAnchor={AboutVideoWrapper} />
      <JustBelowFold>
        <article>
          <h2>Washington Elopement Photography</h2>
          <p>
            You love the mountains, and come alive discovering a new favorite hiking trail. 
            There is no place you'd rather be than adventuring, and exploring the outdoors, 
            in a forest, or watching and listening to the endless waves off the coast. You 
            want your wedding day to also feel this magic you get when out on an adventure. 
            I can <Link to={paths.contact.url}>help you plan</Link> the perfect Washington Elopement. 
            If you want wild, romantic, and creative photography, I can make your dream a reality.
          </p>
        </article>
      
      </JustBelowFold>
      <AboutVideoWrapper/>
      <HomeIntro />
      <ContactMe className="contact-me">
        <Link
          className="pure-button pure-button-primary full-touch-button"
          to={paths.contact.url}
        >
          Contact me to get started
        </Link>
      </ContactMe>
      <SeaToMountainBlock>
        <LupineHeader>
          <h2>Sea To Mountain Adventure Weddings</h2>
          <LupineImgDiv>
            <StaticImage
              src="../images/illustrations/Lupine-color-large.png"
              alt="Lupine Flower"
              height={240}
            />
          </LupineImgDiv>
        </LupineHeader>
        <MeadowImg>
          <StaticImage
            alt="Heather Meados in North Cascades, Mt. Baker, Elopement Photography"
            src="../images/theme/Heather-Meadows-North-Cascades-Elopement-Photographer.jpg"
            width={1800}
          />
          <StaticImage
            alt="Photography by Betty Elaine Logo"
            src="../images/logos/Alt-Logo-8-cream-1000px.png"
            width={250}
            className="stamp"
            style={{ position: "absolute" }}
          />
        </MeadowImg>
      </SeaToMountainBlock>
      <AdventureWeddings>
        <h2>Why Adventure Weddings?</h2>
        <Bird>
          <StaticImage
            alt="Towhee Bird Illustration"
            src="../images/illustrations/Towhee-color-medium.png"
            width={122}
          />
        </Bird>
        <article>
          <p>
            I photograph couples on mountains not just because the scenery is
            epic, but because a lot of my clients, like me, love these places.
            They are adventurous, they love to travel, are activists for our
            public lands, and respect the &nbsp;
            <a href="https://lnt.org/">LNT principles</a>. For my clients, there
            is authenticity in having an adventure wedding. The location they
            choose for their elopement or adventure wedding is meaningful to
            their relationship. I enjoy helping my couples find locations that
            are meaningful to them, so when they look back at their photos,
            every part of the image tells their story.
          </p>
          <AdventureWeddingsLinkP>
            <Link
              className="pure-button pure-button-primary full-touch-button"
              to={paths.pricing.url}
            >
              More About Adventure Weddings
            </Link>
          </AdventureWeddingsLinkP>
        </article>
      </AdventureWeddings>
      <HighlightedReviews />
      <FeaturedItems />
      <Collections>
        <article>
          <div>
            <h2>Elopement &amp; Adventure Wedding Collections</h2>
            <StaticImage
              alt="Champagne Illustration"
              src="../images/illustrations/Champagne2-color-medium.png"
              height={138}
              style={{ position: "absolute" }}
            />
          </div>
          <p>
            <Link
              className="pure-button pure-button-primary full-touch-button"
              to={paths.pricing.url}
            >
              Learn More Here
            </Link>
          </p>
        </article>
      </Collections>
    </>
  )
}

export default Index
