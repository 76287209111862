import React from "react"
import loadable from '@loadable/component'

const AboutVideo = loadable(() => import("./AboutVideo"))

const AboutVideoWrapper = () => {
  return (
    <div id="aboutBettyVideoContainer">
      <AboutVideo/>
    </div>
  )
}

export default AboutVideoWrapper
