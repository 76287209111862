import React from "react"
import styled from "styled-components"
import Picture from "../../Picture"

const Wrapper = styled.div`
  position: absolute;
  left: -9999px;
  top: -9999px;
`

const Preloader = ({ gatsbyImageData }) => {
  if (gatsbyImageData == null) {
    return null;
  }

  return (
    <Wrapper>
      <Picture gatsbyImageData={gatsbyImageData}/>
    </Wrapper>
  )
}

export default Preloader;
