import React from "react"
import styled from "styled-components"

const DEFAULT_LOADING = 'auto';

const StyledPicture = styled.picture`
  img {
    bottom: 0;
    height: 100%;
    left: 0;
    margin: 0;
    max-width: none;
    object-fit: cover;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
`

const Picture = ({ gatsbyImageData, alt, onLoad, loading }) => {
  let key = 1;
  if (gatsbyImageData == null) {
    return null;
  }

  if (loading === undefined) {
    loading = DEFAULT_LOADING;
  }

  // Combine sources for fallback (JPG) and other (Webp):
  const sources = gatsbyImageData.images.sources.concat([gatsbyImageData.images.fallback])
    .map((source, index) => {
      return (<source key={key++} srcSet={source.srcSet} sizes={source.sizes} />);
    })

  return (<StyledPicture>
    {sources}
    <img
      key={key++}
      src={gatsbyImageData.images.fallback.src}
      srcSet={gatsbyImageData.images.sources[0].srcSet}
      sizes={gatsbyImageData.images.fallback.sizes}
      onLoad={onLoad}
      loading={loading}
      alt={alt}
    />
  </StyledPicture>)
}

export default Picture;
