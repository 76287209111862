import React from "react"
import styled from "styled-components"
import Picture from "../../Picture"

const Container = styled.div`
  height: 100%;
  border: none;
  outline: none;

  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  -webkit-tap-highlight-color: transparent;
`

const Inner = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  picture {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
`

const Card = ({ item, index, modIndex, imageRenderedCallback }) => {
  const imageLoaded = () => {
    imageRenderedCallback.call(this, modIndex);
  }

  const loading = modIndex === 0 ? 'eager' : 'lazy';

  return (
    <Container key={modIndex} className='carousel-card'>
      <Inner className='carousel-card-inner'>
        <Picture gatsbyImageData={item.childImageSharp.gatsbyImageData} alt={item.name} onLoad={imageLoaded} key={modIndex} loading={loading}/>
      </Inner>
    </Container>
  )
}

export default Card
